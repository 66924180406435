.table {
    border: 1px solid #c6c6c6;
    .img-wrapper {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto;
    }
    .img-row {
        max-width: 60px;
        width: 60px;
    }
    .form-check {
        margin: 0;
        & label .form-check-sign::before,
        & label .form-check-sign::after {
            top: -17px;
            left: 4px;
        }
    }
    .btn {
        margin: 0;
    }
    small,
    .small {
        font-weight: 300;
    }
    .card-tasks .card-body & {
        margin-bottom: 0;
        >thead>tr>th,
        >tbody>tr>th,
        >tfoot>tr>th,
        >thead>tr>td,
        >tbody>tr>td,
        >tfoot>tr>td {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    >thead>tr>th {
        border-bottom-width: 1px;
        font-size: 1.45em;
        font-weight: $font-weight-light;
        border: 0;
    }
    .radio,
    .checkbox {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        width: 15px;
        .icons {
            position: relative;
        }
        label {
            &:after,
            &:before {
                top: -17px;
                left: -3px;
            }
        }
    }
    >thead>tr>th,
    >tbody>tr>th,
    >tfoot>tr>th,
    >thead>tr>td,
    >tbody>tr>td,
    >tfoot>tr>td {
        padding: 12px 7px;
        vertical-align: middle;
    }
    .th-description {
        max-width: 150px;
    }
    .td-price {
        font-size: 26px;
        font-weight: $font-weight-light;
        margin-top: 5px;
        position: relative;
        top: 4px;
        text-align: right;
    }
    .td-total {
        font-weight: $font-weight-bold;
        font-size: $font-size-h5;
        padding-top: 20px;
        text-align: right;
    }
    .td-actions .btn {
        margin: 0px;
    }
    >tbody>tr {
        position: relative;
    }
}

.table-shopping {
    >thead>tr>th {
        font-size: $font-size-h6;
        text-transform: uppercase;
    }
    >tbody>tr>td {
        font-size: $font-paragraph;
        b {
            display: block;
            margin-bottom: 5px;
        }
    }
    .td-name {
        font-weight: $font-weight-normal;
        font-size: 1.5em;
        small {
            color: $dark-gray;
            font-size: 0.75em;
            font-weight: $font-weight-light;
        }
    }
    .td-number {
        font-weight: $font-weight-light;
        font-size: $font-size-h4;
    }
    .td-name {
        min-width: 200px;
    }
    .td-number {
        text-align: right;
        min-width: 170px;
        small {
            margin-right: 3px;
        }
    }
    .img-container {
        width: 120px;
        max-height: 160px;
        overflow: hidden;
        display: block;
        img {
            width: 100%;
        }
    }
}

.table-responsive {
    overflow: auto;
    padding-bottom: 10px;
}

#tables .table-responsive {
    margin-bottom: 30px;
}

.table thead th {
    border-bottom: none;
}

.table th {
    border-top: none;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #024135;
    border-color: #024135;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #024135;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.visually-hidden {
    display: none;
    // position: relative;
}

.visually-hidden::after {
    visibility: visible;
    position: absolute;
    top: 1px;
    left: 5px;
    content: "";
}