.wrapper {
    position: relative;
    top: 0;
    height: 100vh;

    &.wrapper-full-page {
        min-height: 100vh;
        height: auto;
    }
}

.sidebar,
.off-canvas-sidebar {
    position: absolute;
    width: 260px;
    left: 15px;
    top: 100px;
    z-index: 1030;

    .sidebar-wrapper {
        position: relative;
        overflow: auto;
        width: 100%;
        z-index: 4;
        padding-bottom: 100px;

        .dropdown .dropdown-backdrop {
            display: none !important;
        }

        .navbar-form {
            border: none;
        }
    }

    .logo-img {
        width: 35px;
        height: 35px;
        display: block;
        margin-left: 2px;
        position: absolute;
        top: 12px;

        img {
            width: 35px;
        }
    }

    .navbar-minimize {
        position: absolute;
        right: 20px;
        top: 2px;
        opacity: 1;
        @extend .animation-transition-general;
    }

    .logo-tim {
        border-radius: 50%;
        border: 1px solid #333;
        display: block;
        height: 61px;
        width: 61px;
        float: left;
        overflow: hidden;

        img {
            width: 60px;
            height: 60px;
        }
    }

    .nav {
        margin-top: 20px;
        display: block;

        .caret {
            top: 14px;
            position: absolute;
            right: 10px;
        }

        li {
            >a+div .nav li>a {
                margin-top: 7px;
            }

            >a {
                display: flex !important;
                margin: 10px 0;
                color: $black-color;
                display: block;
                text-decoration: none;
                position: relative;
                cursor: pointer;
                font-size: $font-size-large;
                padding: 10px 8px;
                line-height: $line-height-nav-link;
            }

            &:first-child>a {}

            &:hover:not(.active)>a,
            &:focus:not(.active)>a {
                background-color: $cotin-header-color;
                color: $white-color;

                i {
                    color: $white-color;
                }
            }

            &.active>a,
            &.active-pro>a {
                background-color: $cotin-header-color;
                box-shadow: $box-shadow;

                i {
                    color: $cotin-header-color;
                }
            }

            :hover &.active-pro>a,
            :focus &.active-pro>a {
                background-color: $white-color;
                box-shadow: $box-shadow;
                color: $white-color;

                i {
                    color: $white-color;
                }
            }
        }

        p {
            margin: 0;
            line-height: 30px;
            position: relative;
            display: block;
            height: auto;
        }

        i {
            font-size: 20px;
            float: left;
            margin-right: 12px;
            line-height: 30px;
            width: 34px;
            text-align: center;
            color: $cotin-header-color;
            position: relative;
        }
    }

    .sidebar-background {
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
        border-radius: 4px;
        background-size: cover;
        background-position: center center;

        &:after {
            position: absolute;
            z-index: 3;
            width: 100%;
            height: 100%;
            content: "";
            display: block;
            background: #FFFFFF;
            opacity: 1;
        }
    }

    .logo {
        position: relative;
        padding: $padding-base-vertical $padding-base-horizontal;
        z-index: 4;

        a.logo-mini,
        a.logo-normal {
            @extend .animation-transition-general;
        }

        a.logo-mini {
            opacity: 1;
            float: left;
            width: 34px;
            text-align: center;
            margin-left: 10px;
            margin-right: 12px;
        }

        a.logo-normal {
            display: block;
            opacity: 1;
            @include transform-translate-x(0px);
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 15px;
            height: 1px;
            width: calc(100% - 30px);
            background-color: $cotin-header-color;
        }

        p {
            float: left;
            font-size: 20px;
            margin: 10px 10px;
            color: $black-color;
            line-height: 20px;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        }

        .simple-text {
            padding: $padding-base-vertical 0;
            display: block;
            white-space: nowrap;
            font-size: $font-size-large;
            color: $cotin-header-color;
            text-decoration: none;
            font-weight: $font-weight-bold;
            line-height: 30px;
            overflow: hidden;
        }
    }

    .logo-tim {
        border-radius: 50%;
        border: 1px solid #333;
        display: block;
        height: 61px;
        width: 61px;
        float: left;
        overflow: hidden;

        img {
            width: 60px;
            height: 60px;
        }
    }

    &:before,
    &:after {
        display: block;
        content: "";
        opacity: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    &:after {
        @include icon-gradient($default-color);
        z-index: 3;
    }

    &[data-color="blue"] {
        @include sidebar-color($info-color);
    }

    &[data-color="green"] {
        @include sidebar-color($success-color);
    }

    &[data-color="orange"] {
        @include sidebar-color($orange-color);
    }

    &[data-color="red"] {
        @include sidebar-color($danger-color);
    }

    &[data-color="white"] {
        @include sidebar-color($white-color);
    }

    &[data-color="yellow"] {
        @include sidebar-color($warning-color);
    }
}

.visible-on-sidebar-regular {
    display: inline-block !important;
}

.visible-on-sidebar-mini {
    display: none !important;
}

.off-canvas-sidebar {
    .nav {

        >li>a,
        >li>a:hover {
            color: $white-color;
        }

        >li>a:focus {
            background: rgba(200, 200, 200, 0.2);
        }
    }
}

.main-panel {
    position: relative;
    // width: 100%;
    background-color: $light-gray;
    background-color: #ebecf1;
    @include transition (0.50s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    .main-content {
        padding: 0 30px 30px;
        min-height: 1000px;
        width: 85%;
        margin-left: auto;
        border-radius: 4px;
        margin-top: 4rem;
    }

    .navbar {
        margin-bottom: 0;
    }

    .header {
        margin-bottom: 50px;
    }
}

.perfect-scrollbar-on {

    .sidebar,
    .main-panel {
        height: 100%;
        max-height: 100%;
    }
}

@media (min-width: 991px) {
    .sidebar {
        display: block;
        box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 25%);
        border-radius: 4px;
    }
}

.panel-header {
    height: 75px;
    padding-bottom: 45px;
    @include panel-gradient($cotin-header-color);
    position: relative;
    overflow: hidden;

    .header {
        .title {
            color: $white-color;
        }

        .category {
            max-width: 600px;
            color: $opacity-5;
            margin: 0 auto;
            font-size: 13px;

            a {
                color: $white-color;
            }
        }
    }
}

.panel-header-sm {
    height: 75px;
}

.panel-header-lg {
    height: 380px
}